// 通用页面（登录页、404页等）
import CommonRoutes from './modules/common'

// 设备管理
import Devices from './modules/devices'

// 低代码页面
import Lowcode from './modules/lowcode'

// 演示页面
import Demo from './modules/demo'

// import Test from "./modules/test";

// 高校嵌入页面
import CubeRootPage from './modules/cubeRoot'
// 高校嵌入页面
import MultFlow from './modules/dataView-th'
// h5页面
import H5 from './modules/h5'

const routes = [
    ...CommonRoutes, // 通用路由
    ...Devices, // 多流反应
    ...Lowcode,// 低代码
    // ...Test, // 测试专用
    ...CubeRootPage, // 高校嵌入页面
    ...Demo,// 演示页面
    ...MultFlow,//大屏数字孪生
    ...H5,//h5页面
]

export default routes;