
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import * as THREE from 'three'
import { cloneObj } from './modelOpera'


function loadGLTFMod(path) {
    let gltfLoader = new GLTFLoader();
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/hts/gltf/"); // 设置public下的解码路径，注意最后面的/
    dracoLoader.setDecoderConfig({ type: "js" });
    dracoLoader.preload();
    gltfLoader.setDRACOLoader(dracoLoader);

    const getFrame = new Promise((resolve, reject) => {
        gltfLoader
            .loadAsync(`https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/hts/glb/${path}.glb`)
            .then((objGroup) => {
                resolve(objGroup);
            })
            .catch((err) => {
                reject(err);
            });
    })
    return getFrame

}

function loadGLTFModLoc(path) {
    let gltfLoader = new GLTFLoader();
    let dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("https://fine-fanta.oss-cn-hangzhou.aliyuncs.com/static/hts/gltf/"); // 设置public下的解码路径，注意最后面的/
    dracoLoader.setDecoderConfig({ type: "js" });
    dracoLoader.preload();
    gltfLoader.setDRACOLoader(dracoLoader);

    const getFrame = new Promise((resolve, reject) => {
        gltfLoader
            .loadAsync(`/assets/${path}.glb`)
            .then((objGroup) => {
                resolve(objGroup);
            })
            .catch((err) => {
                console.log(err);

                reject(err);
            });
    })
    return getFrame

}


const loadAllModels = (loadArr, modelObjs, MLStation) => {
    let models = { mAObjs: [], mBObjs: [], mCObjs: [], mDObjs: [], mEObjs: [], mC2Objs: [] }
    loadArr.forEach((item) => {
        item.forEach((modItem, index) => {

            if (modItem[0] == 'wasteLiquid' || modItem[0] == 'frame') {
                modelInit(models, MLStation, index, modelObjs[modItem[0]].scene.children[0], ...modItem)
            } else if (modItem[0] === 'roman' || modItem[0] === 'sixWay' || modItem[0] === 'lcms') {
                // modelInit(MLStation, index, modelObjs[modItem[0]].scene, ...modItem)
            } else {
                modelInit(models, MLStation, index, modelObjs[modItem[0]].scene.children[1], ...modItem)
            }
        })
    })
    loadGLTFMod("cover").then((object) => {
        models['cap'] = object;
    });
    return models
}
const buildMaterial = new THREE.MeshBasicMaterial({
    color: 0x00BFFF,     // 颜色
    transparent: true,    // 是否开启使用透明度
    opacity: 0.4,        // 透明度
    // depthWrite: true,    // 关闭深度写入 透视效果
    side: THREE.DoubleSide, // 双面显示
});
const modelInit = (modelObjs, MLStation, index, object, path, position, name, type, parent) => {
    try {
        // getFrame.then((object) => {
        parent === undefined || (object.module = index)
        object.traverse((child) => {
            child.castShadow = true;
            child.name = name
            child['modType'] = type
            if (type == 'bot') {
                child['selected'] = false
                child.geometry && child.material.emissive.set(0xC0C0C0)

            }
        })
        let box = new THREE.Box3().setFromObject(object); // 获取模型的包围盒
        let mdlen = box.max.x - box.min.x; // 模型长度
        let mdwid = box.max.z - box.min.z; // 模型宽度
        let mdhei = box.max.y - box.min.y; // 模型高度
        let x1 = box.min.x + mdlen / 2; // 模型中心点坐标X
        let y1 = box.min.y + mdhei / 2; // 模型中心点坐标Y
        let z1 = box.min.z + mdwid / 2; // 模型中心点坐标Z
        object.position.set(-x1 + position[0], -y1 + position[1], -z1 + position[2]); // 将模型进行偏移

        switch (path) {
            case 'wasteLiquid': {
                // wasteLiquid = object
                modelObjs['wasteLiquid'] = object
                // object.traverse(child => {
                //     child.geometry && (child.material = buildMaterial)
                // })
                break;
            }
            case 'frame':
                {
                    modelObjs['frame'] = object

                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    break;
                    // let geoLine = new THREE.geometry()
                }
            case 'shake':
                {
                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    modelObjs['A'] = object
                    loadGLTFMod("srbot").then((srBot) => {
                        for (let j = 0; j < 3; j++) {
                            for (let i = 0; i < 8; i++) {
                                let srBotOne = cloneObj(srBot.scene.children[1])
                                modelInit(modelObjs, MLStation, index, srBotOne, 'srBot', [-4 + j * 4, 6, 14 - i * 4], `A-${j + 1}-${i + 1}`, 'bot', object)
                            }
                        }
                    })
                    break;
                }
            case 'shake2':
                {
                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    modelObjs['B'] = object


                    loadGLTFMod('srbot').then((srBot) => {
                        for (let j = 0; j < 3; j++) {
                            for (let i = 0; i < 8; i++) {
                                modelInit(modelObjs, MLStation, index, cloneObj(srBot.scene.children[1]), 'srBot', [-4 + j * 4, 6, 14 - i * 4], `B-${j + 1}-${i + 1}`, 'bot', object)

                            }
                        }

                    })
                    break;

                }
            case 'lStore':
                {
                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    modelObjs['C'] = object


                    loadGLTFMod('100ml').then((bot100ml) => {

                        for (let j = 0; j < 2; j++) {
                            for (let i = 0; i < 6; i++) {
                                modelInit(modelObjs, MLStation, index, cloneObj(bot100ml.scene.children[1]), '100ml', [-3.5 + j * 5.5, 4, 14 - i * 5.5], `C-${j + 1}-${i + 1}`, 'bot', object)
                            }
                        }

                    })
                    break;

                }
            case 'lStore2':
                {
                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    object.rotateY(3 * Math.PI / 2)
                    // object.rotateY(Math.PI / 2)
                    modelObjs['C2'] = object
                    loadGLTFMod('100ml').then((bot100ml) => {
                        for (let j = 0; j < 6; j++) {
                            for (let i = 0; i < 2; i++) {
                                modelInit(modelObjs, MLStation, index, cloneObj(bot100ml.scene.children[1]), '100ml2', [- i * 5.5 + 2.5, 4, 14 - j * 5.5], `C2-${j + 1}-${i + 1}`, 'bot', object)
                            }
                        }
                    })
                    break;
                }
            case 'seSupport':
                {
                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    modelObjs['E'] = object


                    loadGLTFMod('extBot').then((extBot) => {
                        for (let j = 0; j < 3; j++) {
                            for (let i = 0; i < 5; i++) {
                                modelInit(modelObjs, MLStation, index, cloneObj(extBot.scene.children[1]), 'extBot', [-7 + j * 3, 6, 14 - i * 6], `E-${j + 1}-${i + 1}`, 'bot', object)

                            }
                        }

                    })
                    break;

                }
            case 'SPESupport':
                {
                    // object.traverse(child => {
                    //     child.geometry && (child.material = buildMaterial)
                    // })
                    modelObjs['D'] = object


                    loadGLTFMod('reBot').then((reBot) => {
                        for (let j = 0; j < 3; j++) {
                            for (let i = 0; i < 10; i++) {
                                let rebotClone = cloneObj(reBot.scene.children[1])
                                modelInit(modelObjs, MLStation, index, rebotClone, 'reBot', [-3 + j * 3, 6, 13 - i * 3], `D-${j + 1}-${i + 1}`, 'bot', object)

                            }
                        }

                    })
                    break;

                }

            case 'x': {
                modelObjs['x'] = object

                break;
            }
            case 'y': {
                modelObjs['y'] = object

                break;
            }
            case 'z': {
                object.children[1].scale.y = 48
                object.children[1].position.y = -380
                modelObjs['z'] = object

                break;
            }
            case 'r': {
                modelObjs['r'] = object

                break;
            }
            case 'grap': {
                modelObjs['grab'] = object
                break;
            }
            case '100ml': {
                modelObjs['mCObjs'].push(object)
                break;
            }
            case '100ml2': {
                modelObjs['mC2Objs'].push(object)
                break;
            }
            case 'reBot': {
                modelObjs['mDObjs'].push(object)
                break;
            }
            case 'extBot': {
                modelObjs['mEObjs'].push(object)

                break;
            }
            case 'mlModule': {
                modelObjs['mlModule'] = object
                break;
            }
            case 'seal': {
                modelObjs['seal'] = object

                break;
            }
            case 'srBot': {
                if (object.module === 0) {
                    modelObjs['mAObjs'].push(object)
                } else {
                    modelObjs['mBObjs'].push(object)
                }

                break
            }
            case 'sixWay': {
                modelObjs['sixWay'] = object
                object.rotateX(Math.PI)
                break;
            }
            case 'lcms': {
                modelObjs['lcms'] = object
                // lcms = object
                object.rotateX(3 * Math.PI / 2)
                break;
            }
            case 'roman': {
                modelObjs['roman'] = object
                // roman = object
                object.rotateX(Math.PI)
                object.rotateZ(Math.PI)
                break;
            }
            case "cover": {
                modelObjs['cover'] = object
                break;
            }
            default:
                break;
        }

        parent === undefined ? MLStation.scene.add(object) : parent.add(object)
    } catch (error) {
        console.log(error);
    }

    // })
}


export { loadGLTFMod, loadGLTFModLoc, loadAllModels, modelInit }